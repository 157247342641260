.simTableDynamic {
  border-collapse: collapse; /* This removes spacing between cells */
  width: 100%; /* Optional: Set the table width to 100% of its container */
  border: 1px solid #000; /* Add a border around the entire table */
}

th,
td {
  border: 1px solid #000; /* Add borders to table header and data cells */
  padding: 8px; /* Add some padding for better spacing */
  text-align: left; /* Align cell content to the left */
}

.simSelectField {
  display: flex;
  direction: row;
  justify-content: space-between;
  padding: 0px 12px;
  border-radius: 8px;
  transition: 0.5s;
  border-style: solid;
  border-color: #d9d9d9;
  border-width: 1px;
  min-width: 10vw;
  max-width: 18vw;
  p {
    margin-right: 0.6vw;
    // text-align: justify;
    // text-justify: inter-word;
  }
}

.simSelectField:hover {
  border-style: solid;
  border-width: 0.5px;
  border-color: #8645ff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media screen and (max-width: 600px) {
  .simSelectField {
    min-width: 20vw;
    max-width: 30vw;
  }
}
